import Parcel from "single-spa-react/parcel";
import { ParcelConfig } from "single-spa";

const pricegenCompetitorGridModalConfig: ParcelConfig<{}> = () =>
  System.import("@app/visops-pricegen-competitorgrid-upload");

type PricegenCompetitorGridProps = {
  currentPage: number;
  isOpen: boolean;
  onClose: () => void;
};

const PricegenCompetitorGrid: React.FC<PricegenCompetitorGridProps> = ({
  currentPage,
  isOpen,
  onClose,
}) => {
  return (
    <div className="pricegen-competitor-grid-dialog-mount">
      <Parcel
        config={pricegenCompetitorGridModalConfig}
        customProps={{
          currentPage,
          isOpen,
          onClose,
        }}
      />
    </div>
  );
};
 
export default PricegenCompetitorGrid;